<template>
<div class="white">
    <v-row justify="center" align="center" class="text-center">
        <v-container>
            <v-avatar size="200" tile>
                <v-container>
                    <v-img :src="$config.logo" />
                </v-container>
            </v-avatar>
            <h2 class="mt-2" style="color: #122940">
                {{ $config.title }}
            </h2>
            <h2 class="mt-2" style="color: #122940">
                منظومة سير العمل الإداري
            </h2>
            <h3 class="mt-2" style="color: #0080ff">|| مسعى ||</h3>
        </v-container>
    </v-row>
    <br />
    <v-container>
        <v-form @submit.prevent="submit()" v-model="valid" ref="form">
            <v-container>
                <v-card outlined>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6">
                                <h3 class="mb-2">
                                    اسم مقدم الطلب
                                    <span class="required">*</span>
                                </h3>
                                <v-text-field v-model="item.name" placeholder="اسم مقدم الطلب" filled outlined hide-details="auto" :rules="[$global.state.required()]" />
                            </v-col>

                            <v-col cols="12" md="6">
                                <h3 class="mb-2">ملاحظات</h3>
                                <v-text-field v-model="item.description" placeholder="ملاحظات" filled outlined hide-details="auto" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-col cols="12" md="12">
                    <v-row class="mt-2">
                        <v-btn type="submit" :loading="$global.state.loading" x-large color="primary" class="btn" :disabled="!valid">{{ $t("save") }}</v-btn>
                    </v-row>
                </v-col>
            </v-container>
        </v-form>
    </v-container>
</div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            valid: false,
            item: {
                "name": "",
                "description": "",
                "workflowId": this.$route.params.workflowId,
                "organizationId": "0cc7ff4b-eb0e-4f51-81b6-ce3f983c738a",
                "statusId": "8605ae2f-ae5e-4dd9-81c0-113e82b5e166",
                "note": ""
            }
        }
    },

    methods: {
        async submit(){
            this.loading = true
            try {
                let res = await this.$http.post(`order`,this.item)
                await this.getOrderNode(res.data.result.id)
                console.log(res)
            } catch (err) {
                console.log(err)
            }
        },

        async getOrderNode(orderId){
            this.loading = true
            try {
                let res = await this.$http.get(`OrderNode?OrderId=${orderId}`)
                let currentNode = res.data.result.find(item => item.node.group.id === "f536d3bc-506b-4eb2-9dfc-0748dd6d3ce1");
                console.log(currentNode)
                this.$router.push({
                path:  "/visitor/orderData/" +
                currentNode.node.id +
                        "/" +
                        currentNode.node.name +
                        "/" +
                        currentNode.node.mainNodes[0].mainNodeId +
                        "/" +
                        orderId +
                        "/" +
                        currentNode.id +
                        "/" +
                        currentNode.node.workflow.id +
                        "/add" 
                });
                console.log(res)
            } catch (err) {
                console.log(err)
            }
        }
    },
}
</script>
